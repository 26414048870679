define('consumer-portal/pods/collect-parcel/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        ajax: _ember['default'].inject.service(),

        model: function model(params) {
            return params['token'];
        },

        setupController: function setupController(controller, model) {
            controller.set("loading", true);
            this.get('ajax').request('../lockerAccess?c=' + model).then(function (consignment) {
                controller.set("consignment", consignment);
                controller.set("loading", false);
            }, function () {
                controller.set("loading", false);
            });
        }
    });
});