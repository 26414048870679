define('consumer-portal/instance-initializers/page-tracking', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(appInstance) {
    var router = appInstance.lookup('router:main');
    router.on('didTransition', function () {
      var page = this.get('url');
      var title = this.getWithDefault('currentRouteName', 'unknown');
      this.metrics.trackPage({ page: page, title: title });
    });
  }

  exports['default'] = {
    name: 'page-tracking',
    initialize: initialize
  };
});