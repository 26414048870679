define('consumer-portal/pods/components/request-credit-modal/component', ['exports', 'semantic-ui-ember/components/ui-modal'], function (exports, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    name: 'request-credit',
    classNames: ['request-credit'],

    abn: "",

    init: function init() {
      this._super.apply(this, arguments);
      var self = this;
      this.set("attrs.onHide", function () {
        self.set("abn", "");
      });
    },

    actions: {
      confirm: function confirm() {
        this.get("onRequest")(this.get("abn"));
      }
    }
  });
});