define('consumer-portal/pods/consignments/new/controller', ['exports', 'ember', 'consumer-portal/shapes/destination', 'consumer-portal/shapes/pickup', 'consumer-portal/shapes/consignment', 'consumer-portal/shapes/creditCard', 'consumer-portal/shapes/itemDetails'], function (exports, _ember, _consumerPortalShapesDestination, _consumerPortalShapesPickup, _consumerPortalShapesConsignment, _consumerPortalShapesCreditCard, _consumerPortalShapesItemDetails) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    ajax: _ember['default'].inject.service('ajax'),
    stripe: _ember['default'].inject.service(),
    config: _ember['default'].inject.service('config'),
    notify: _ember['default'].inject.service('notify'),

    queryParams: ["existing_id"],
    existing_id: null,

    consignment: null,

    state: 'serviceType',

    lodgementResult: null,

    recentAddressAddId: null,

    creditCard: null,
    saveCard: false,
    useNewCard: false,
    savedCards: [],
    existingCard: null,

    promoCode: null,
    promoCodeDetails: null,
    serviceTypes: ["standard", "same-day", "international", "next-day"],

    promoDiscountAmount: _ember['default'].computed("fullConsignment.totalPrice", "promoCodeDetails.fractionalDiscount", "promoCodeDetails.flatDiscount", function () {
      if (this.get("promoCodeDetails.flatDiscount")) {
        return this.get("promoCodeDetails.flatDiscount");
      } else {
        return this.get("fullConsignment.totalPrice") * this.get("promoCodeDetails.fractionalDiscount");
      }
    }),

    promoTotal: _ember['default'].computed("promoDiscountAmount", "fullConsignment.totalPrice", function () {
      return Math.max(0, this.get("fullConsignment.totalPrice") - this.get("promoDiscountAmount"));
    }),

    //TODO
    hasAccount: true,
    paymentType: "Account",
    //TODO

    dropOffResult: {
      searched: false,
      lat: -33.8590647,
      lon: 151.1724718,
      locations: []
    },

    times: ["9:00AM", "10:00AM", "11:00AM", "12:00PM", "1:00PM", "2:00PM", "3:00PM", "4:00PM"],

    termsConditions: false,

    states: _ember['default'].A(['NSW', 'QLD', 'ACT', 'WA', 'TAS', 'SA', 'VIC', 'NT']),

    suburbUrl: _ember['default'].computed('session.data.authenticated', 'config.apiURL', {
      get: function get() {
        var jwt = this.get('session.data.authenticated.jwt');
        return this.get("config.apiURL") + '/suburbSuggestions?suburb={query}&token=' + jwt;
      }
    }),

    /*
     * HACK: The version of semantic-ui-ember used does not allow auto-completed fields
     * to find suggestions by a parameter (i.e. country code).
     *
     * TODO: See if a later version of semantic-ui will allow us to do this more cleanly.
     */
    intlAddressSuggestionUrl: _ember['default'].observer('session.data.authenticated', 'config.apiURL', 'consignment.destination.countryCode', function () {
      var countryCode = this.get('consignment.destination.countryCode');
      var self = this;
      _ember['default'].$(".international-autocomplete").search({
        apiSettings: {
          url: this.get('config.apiURL') + '/intlAddressSuggestions?input={query}&countryCode=' + countryCode
        },
        type: "intlAddressResult",
        cache: false,
        onSelect: function onSelect(response) {
          self.send("updateDestinationAddress", response);
        }
      });
    }),

    init: function init() {
      this.send("refreshAddresses");
      this.set("promoCode", null);
      this.set("promoCodeDetails", null);
      this.set("consignment", _consumerPortalShapesConsignment['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
        serviceType: "standard",
        pickupType: "pickup",
        pickup: _consumerPortalShapesPickup['default'].create(_ember['default'].getOwner(this).ownerInjection()),
        items: []
      }));
      this.set("consignment.destination", _consumerPortalShapesDestination['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
        consignment: this.get("consignment")
      }));
      this.get("consignment.items").pushObject(_consumerPortalShapesItemDetails['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
        type: this.get("consignment.isInternational") ? "Custom" : "HUBBED",
        product: this.get("consignment.isInternational") ? null : "Small Satchel",
        consignment: this.get("consignment")
      }));
      this.set('creditCard', _consumerPortalShapesCreditCard['default'].create(_ember['default'].getOwner(this).ownerInjection()));

      /* Start Config for address dropdowns */
      _ember['default'].$.fn.search.settings.templates["suburbResult"] = function (response, fields) {
        var html = '';
        if (response[fields.results] !== undefined) {
          _ember['default'].$.each(response[fields.results], function (index, result) {
            html += '<a class="result">';
            html += '<div class="content">';
            html += '<div class="title">' + result["suburb"] + '</div>';
            html += '<div class="description">' + result["state"] + " " + result["postcode"] + '</div>';
            html += '</div>';
            html += '</a>';
          });
          return html;
        }
        return false;
      };
      _ember['default'].$.fn.search.settings.templates["intlAddressResult"] = function (response, fields) {
        var html = '';
        if (response[fields.results] !== undefined) {
          _ember['default'].$.each(response[fields.results], function (index, result) {
            html += '<a class="result">';
            html += '<div class="content">';
            html += '<div class="title">' + result["description"] + '</div>';
            html += '</div>';
            html += '</a>';
          });
          return html;
        }
        return false;
      };
    },
    /* End config */

    actions: {
      setServiceType: function setServiceType(value) {
        this.set("consignment.serviceType", value);
        if (value === "international") {
          this.set("consignment.pickupType", "dropoff");
          this.set("consignment.destination.countryCode", null);
          this.get("consignment.items").forEach(function (item) {
            _ember['default'].setProperties(item, {
              type: "Custom",
              product: null
            });
          });
        } else {
          this.set("consignment.pickupType", "pickup");
          this.set("consignment.destination.countryCode", "AU");
        }
      },

      setPaymentType: function setPaymentType(value) {
        this.set("paymentType", value);
      },

      searchAddressBook: function searchAddressBook() {
        _ember['default'].$('.ui.search-address-book.modal').modal('show');
      },

      prefillReceiver: function prefillReceiver(entry) {
        _ember['default'].$('.ui.search-address-book.modal').modal('hide');
        this.get("consignment.destination").setProperties({
          company: entry.companyName,
          firstName: entry.firstName,
          lastName: entry.lastName,
          address: entry.address,
          postcode: entry.postcode,
          state: entry.state,
          suburb: entry.suburb,
          countryCode: entry.countryCode || "AU",
          phone: entry.phone,
          email: entry.email
        });
      },

      setPickupType: function setPickupType(type) {
        this.set("consignment.pickupType", type);
      },

      addNewItem: function addNewItem(item) {
        if (item.get("validations.isValid")) {
          this.get('consignment.items').pushObject(_consumerPortalShapesItemDetails['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
            type: this.get("consignment.isInternational") ? "Custom" : "HUBBED",
            product: this.get("consignment.isInternational") ? null : "Small Satchel",
            consignment: this.get("consignment")
          }));
        }
      },

      removeItem: function removeItem(item) {
        this.get("consignment.items").removeObject(item);
      },

      updateDestinationSuburb: function updateDestinationSuburb(value) {
        this.get("consignment.destination").setProperties({
          postcode: value.postcode,
          state: value.state,
          suburb: value.suburb,
          countryCode: "AU"
        });
      },

      searchSuburbQuery: function searchSuburbQuery(value) {
        this.set("consignment.destination.suburb", value);
      },

      updateDestinationAddress: function updateDestinationAddress(value) {
        var _this = this;

        this.get('ajax').request('placeIdAddressFormat?placeId=' + value.placeId).then(function (formattedAddress) {
          _this.get("consignment.destination").setProperties({
            address: formattedAddress.address,
            postcode: formattedAddress.postcode,
            state: formattedAddress.state,
            suburb: formattedAddress.suburb
          });
        }, console.log);
      },

      searchAddressQuery: function searchAddressQuery(value) {
        this.set("consignment.destination.address", value);
      },

      updatedDestinationState: function updatedDestinationState(value) {
        this.set("consignment.destination.state", value);
      },

      setState: function setState(state) {
        this.set("state", state);
      },

      updateTerms: function updateTerms(e) {
        this.set("termsConditions", e);
      },

      updateNoDangerousGoods: function updateNoDangerousGoods(e) {
        this.set("noDangerousGoods", e);
      },

      getQuote: function getQuote() {
        var _this2 = this;

        if (!this.get("fullConsignment")) {
          this.get('ajax').post('consignments', {
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify(this.get("consignment").toJSON())
          }).then(function (result) {
            _this2.set('fullConsignment', result);
            _this2.set('existing_id', result.id);
            _this2.send("updateQuoteAndTransition");
          }, function (error) {
            console.log(error);
          });
        } else {
          this.get('ajax').post('consignments/' + this.get("existing_id") + '/update', {
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify(this.get("consignment").toJSON())
          }).then(function (result) {
            _this2.set('fullConsignment', result);
            _this2.set('existing_id', result.id);
            _this2.send("updateQuoteAndTransition");
          }, function (error) {
            _this2.get('notify').error('Error: ' + error.errors[0].detail.message);
            console.log(error);
          });
        }
      },

      updateQuoteAndTransition: function updateQuoteAndTransition() {
        var _this3 = this;

        this.get('ajax').post('consignments/' + this.get("existing_id") + '/quotes').then(function (result) {
          _this3.set('fullConsignment', result);
          _this3.set("state", "bookingDetails");
        }, function (error) {
          console.log(error);
          _this3.set("state", "bookingDetails");
          _this3.set("quoteErrors", error.errors.map(function (e) {
            return e.detail.data;
          }));
        });
      },

      validatePromoCode: function validatePromoCode() {
        var _this4 = this;

        this.get('ajax').request('promoCodes/validate?code=' + this.get("promoCode") + '&service=' + this.get("consignment.serviceTypeId")).then(function (result) {
          _this4.set('promoCodeDetails', result);
        }, function (error) {
          _this4.set('promoCodeDetails', error.errors.map(function (e) {
            return e.detail;
          })[0]);
        });
      },

      completeTransaction: function completeTransaction() {
        var _this5 = this;

        var stripe = this.get('stripe');
        var self = this;
        var card = {
          cvc: this.get("creditCard.cvc"),
          number: this.get("creditCard.number"),
          exp: this.get("creditCard.exp"),
          name: this.get("creditCard.name")
        };

        if (this.get("paymentType") === "Card" && !this.get("useNewCard") && this.get("existingCard") && this.get("termsConditions")) {
          this.set("completeLoading", true);
          var request = { savedCardId: this.get("existingCard.id"), saveCard: false, useCreditAccount: false, promoCodeId: this.get("promoCodeDetails.id") ? this.get("promoCodeDetails.id") : null };
          this.get('ajax').post('consignments/' + this.get("fullConsignment.id") + '/pay', {
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify(request)
          }).then(function (result) {
            self.set("state", "completed");
            self.set("completeLoading", false);
            self.set("lodgementResult", result);
          }, function (error) {
            console.log(error);
            self.set("completeLoading", false);
            self.set("submissionError", error.errors.map(function (e) {
              return e.detail;
            }));
          });
        }

        if (this.get("paymentType") === "Card" && this.get("useNewCard") && this.get("creditCard").get('validations.isValid') && this.get("termsConditions")) {
          this.set("completeLoading", true);
          stripe.card.createToken(card).then(function (result) {
            console.log("Result", result);
            var request = { stripeToken: result.id, saveCard: _this5.get("saveCard"), useCreditAccount: false, promoCodeId: _this5.get("promoCodeDetails.id") ? _this5.get("promoCodeDetails.id") : null };
            _this5.get('ajax').post('consignments/' + _this5.get("fullConsignment.id") + '/pay', {
              contentType: 'application/json; charset=utf-8',
              data: JSON.stringify(request)
            }).then(function (result) {
              self.set("state", "completed");
              self.set("completeLoading", false);
              self.set("lodgementResult", result);
            }, function (error) {
              console.log(error);
              self.set("completeLoading", false);
              self.set("submissionError", error.errors.map(function (e) {
                return e.detail;
              }));
            });
          }, function (error) {
            console.log("Error", error);
            self.set("completeLoading", false);
            self.set("submissionError", error.errors.map(function (e) {
              return e.detail;
            }));
          });
        }

        if (this.get("paymentType") === "Account" && this.get("termsConditions") && this.get("creditAccount.limit") - this.get("creditAccount.currentSpend") >= this.get("fullConsignment.totalPrice")) {
          this.set("completeLoading", true);
          var request = { saveCard: false, useCreditAccount: true, promoCodeId: this.get("promoCodeDetails.id") ? this.get("promoCodeDetails.id") : null };
          this.get('ajax').post('consignments/' + this.get("fullConsignment.id") + '/pay', {
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify(request)
          }).then(function (result) {
            self.set("state", "completed");
            self.set("completeLoading", false);
            self.set("lodgementResult", result);
          }, function (error) {
            self.set("completeLoading", false);
            self.set("submissionError", error.errors.map(function (e) {
              return e.detail;
            }));
          });
        }
      },

      finish: function finish() {
        this.transitionToRoute("consignments.tracking");
      },

      duplicate: function duplicate() {
        var _this6 = this;

        this.set("promoCode", null);
        this.set("promoCodeDetails", null);
        this.get('ajax').post('consignments/' + this.get("fullConsignment.id") + '/duplicate').then(function (result) {
          _this6.transitionToRoute("consignments.new", { queryParams: { existing_id: result.id } });
          _this6.send("reloadAll");
        }, function (error) {
          console.log(error);
        });
      },

      useNewCard: function useNewCard() {
        this.set("useNewCard", true);
      },

      useSavedCard: function useSavedCard() {
        this.set("useNewCard", false);
      },

      discardProgress: function discardProgress() {
        var _this7 = this;

        if (this.get("existing_id")) {
          this.get('ajax').del('consignments/' + this.get("existing_id")).then(function () {
            _this7.transitionToRoute("consignments.ready-to-send");
          }, function () {
            _this7.transitionToRoute("consignments.ready-to-send");
          });
        } else {
          this.transitionToRoute("consignments.ready-to-send");
        }
      },

      keepProgress: function keepProgress() {
        var _this8 = this;

        if (this.get("existing_id")) {
          //Submit update consignment
          this.get('ajax').post('consignments/' + this.get("existing_id") + '/update', {
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify(this.get("consignment").toJSON()),
            dataType: "text"
          }).then(function () {
            _this8.transitionToRoute("consignments.ready-to-send");
          }, function (error) {
            console.log(error);
          });
        } else {
          //Submit save consignment
          this.get('ajax').post('consignments', {
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify(this.get("consignment").toJSON())
          }).then(function () {
            _this8.transitionToRoute("consignments.ready-to-send");
          }, function (error) {
            console.log(error);
          });
        }
      },

      addAddress: function addAddress() {
        _ember['default'].$('.ui.add-address.modal').modal('show');
      },

      cancelModal: function cancelModal() {
        _ember['default'].$('.ui.cancel.modal').modal('show');
      },

      openImagePopup: function openImagePopup(image) {
        console.log(image);
        this.set("popupImage", image);
        _ember['default'].$(".ui.popup-image.modal").modal("show");
      },

      saveNewAddress: function saveNewAddress(address) {
        var _this9 = this;

        this.get('ajax').post('pickupAddresses', {
          contentType: 'application/json; charset=utf-8',
          data: JSON.stringify(address)
        }).then(function (newAddress) {
          _ember['default'].$('.ui.add-address.modal').modal('hide');
          _this9.set("recentAddressAddId", newAddress.id);
          _this9.send("refreshAddresses");
        }, function (error) {
          console.log(error);
        });
      },

      refreshAddresses: function refreshAddresses() {
        var _this10 = this;

        this.get('ajax').request('pickupAddresses').then(function (results) {
          _this10.set("pickupAddresses", results);
          if (results.length > 0) {
            _ember['default'].run.later(function () {
              var recentAdd = _this10.get("pickupAddresses").find(function (s) {
                return s.id === _this10.get("recentAddressAddId");
              });
              if (recentAdd) {
                _this10.set("consignment.pickup.address", recentAdd);
                _ember['default'].$(".pickup-dropdown").dropdown('set selected', _ember['default'].guidFor(recentAdd));
              } else {
                var defaultAddr = _this10.get("pickupAddresses").find(function (s) {
                  return s['default'];
                });
                if (defaultAddr) {
                  _this10.set("consignment.pickup.address", defaultAddr);
                  _ember['default'].$(".pickup-dropdown").dropdown('set selected', _ember['default'].guidFor(defaultAddr));
                } else {
                  _this10.set("consignment.pickup.address", _this10.get("pickupAddresses")[0]);
                  _ember['default'].$(".pickup-dropdown").dropdown('set selected', _ember['default'].guidFor(_this10.get("pickupAddresses")[0]));
                }
              }
            }, 0);
          }
        }, function (error) {
          console.log(error);
        });
      },

      searchDropOffLocations: function searchDropOffLocations() {
        var _this11 = this;

        this.get('ajax').request('locations/nearest?q=' + this.get("dropOffPostcode")).then(function (result) {
          _this11.set("dropOffResult", result);
          _this11.set("dropOffResult.searched", true);
        }, function (error) {
          _this11.set("dropOffResult.searched", true);
          _this11.set("dropOffResult.locations", []);
          console.log(error);
        });
      }

    }

  });
});