define('consumer-portal/validators/valid-credit-card-number', ['exports', 'ember', 'ember-cp-validations/validators/base'], function (exports, _ember, _emberCpValidationsValidatorsBase) {

  var ValidCreditCardNumber = _emberCpValidationsValidatorsBase['default'].extend({
    stripe: _ember['default'].inject.service(),

    validate: function validate(value) {
      return this.get("stripe").card.validateCardNumber(value) ? true : "Invalid Card Number";
    }
  });

  exports['default'] = ValidCreditCardNumber;
});