define('consumer-portal/pods/find-hubbstation/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),

    queryParams: ['query'],

    query: '',
    center: {
      lat: -25.641526,
      lon: 133.59375
    },
    zoom: 5,
    dropOffResult: {
      searched: false,
      locations: []
    },

    actions: {
      getMap: function getMap(map) {
        this.set('map', map);
        //Set up Zoom at bottom
        map.target.zoomControl.setPosition('bottomleft');
      },

      search: function search() {
        var self = this;
        if (this.get("query").length > 0) {
          this.set('loading', true);
          this.get('ajax').request('locations/nearest?q=' + this.get("query")).then(function (result) {
            self.set("dropOffResult", result);
            _ember['default'].run.later(function () {
              self.get("map").target.setView({ lat: result.lat, lon: result.lon }, 14, { animate: true });
            }, 300);
            self.set("dropOffResult.searched", true);
          }, function (error) {
            self.set("dropOffResult.searched", true);
            self.set("dropOffResult.locations", []);
            console.log(error);
          });
        }
      }
    }
  });
});