define('consumer-portal/pods/components/cancel-modal/component', ['exports', 'semantic-ui-ember/components/ui-modal'], function (exports, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    name: 'cancel',
    classNames: ['cancel'],

    actions: {
      close: function close() {
        this.execute('hide');
      },
      keep: function keep() {
        this.execute('hide');
        this.get("onKeep")();
      },
      discard: function discard() {
        this.execute('hide');
        this.get("onDiscard")();
      }
    }
  });
});