define('consumer-portal/shapes/itemDetails', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {

    var Validations = (0, _emberCpValidations.buildValidations)({
        type: {
            description: "Type",
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
                'in': ["HUBBED", "Custom"]
            })]
        },
        length: {
            description: "Length",
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
                allowString: true,
                integer: true,
                gt: 0
            }), (0, _emberCpValidations.validator)('number', {
                allowString: true,
                lte: 105,
                disabled: _ember['default'].computed.not("model.consignment.isInternational")
            })]
        },
        width: {
            description: "Width",
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
                allowString: true,
                integer: true,
                gt: 0
            })]
        },
        height: {
            description: "Height",
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
                allowString: true,
                integer: true,
                gt: 0
            })]
        },
        weight: {
            description: "Weight",
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
                allowString: true,
                gt: 0
            }), (0, _emberCpValidations.validator)('number', {
                allowString: true,
                lte: 5,
                disabled: _ember['default'].computed.not("model.consignment.isInternational")
            })]
        },
        packagingType: {
            description: "Packaging Type",
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
                'in': ["box", "satchel"]
            })]
        },
        cost: {
            description: "Value of contents",
            validators: [(0, _emberCpValidations.validator)('presence', {
                presence: true,
                disabled: _ember['default'].computed.not("model.consignment.isInternational")
            }), (0, _emberCpValidations.validator)('number', {
                message: "Value of contents must be less than or equal to $2000 AUD",
                disabled: _ember['default'].computed.not("model.consignment.isInternational"),
                allowString: true,
                gt: 0,
                lte: 2000
            })]
        },
        skuNumber: {
            description: "SKU Number",
            validators: [(0, _emberCpValidations.validator)('presence', {
                presence: true,
                disabled: _ember['default'].computed.not("model.consignment.isInternational")
            }), (0, _emberCpValidations.validator)('length', {
                min: 1,
                max: 50,
                disabled: _ember['default'].computed.not("model.consignment.isInternational")
            })]
        },
        description: {
            description: "Description",
            validators: [(0, _emberCpValidations.validator)('presence', {
                presence: true,
                disabled: _ember['default'].computed.not("model.consignment.isInternational")
            }), (0, _emberCpValidations.validator)('length', {
                min: 3,
                max: 50,
                disabled: _ember['default'].computed.not("model.consignment.isInternational")
            })]
        },
        sumDimensions: {
            description: "Sum of the dimensions",
            validators: [(0, _emberCpValidations.validator)('number', {
                allowString: true,
                lte: 150,
                disabled: _ember['default'].computed.not("model.consignment.isInternational")
            })]
        }
    });

    exports['default'] = _ember['default'].Object.extend(Validations, {
        type: "HUBBED",
        product: "Small Satchel",
        length: 30,
        width: 20,
        height: 1,
        weight: 0.5,
        packagingType: "satchel",
        description: null,
        cost: null,
        skuNumber: null,

        consignment: null,

        sumDimensions: _ember['default'].computed('length', 'width', 'height', function () {
            return parseInt(this.get("length")) + parseInt(this.get("width")) + parseInt(this.get("height"));
        }),

        toJSON: function toJSON() {
            return {
                packagingType: this.get("type"),
                packagingProduct: this.get("product"),
                quantity: 1,
                length: parseInt(this.get("length")),
                height: parseInt(this.get("height")),
                width: parseInt(this.get("width")),
                physicalWeight: parseFloat(this.get("weight")),
                ratePackagingType: this.get("packagingType"),
                description: this.get("description"),
                centsValue: Math.round(parseFloat(this.get("cost")) * 100),
                skuNumber: this.get("skuNumber")
            };
        }
    });
});