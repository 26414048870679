define('consumer-portal/pods/consignments/archive/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    notify: _ember['default'].inject.service('notify'),

    queryParams: ["page"],

    loading: false,
    page: 1,
    totalPages: 1,
    consignments: _ember['default'].A([]),

    actions: {
      showDetails: function showDetails(consignment) {
        this.set("showingConsignment", consignment);
        _ember['default'].$(".ui.modal.consignment-details").modal('show');
      },

      unarchiveConsignment: function unarchiveConsignment(consignment) {
        var self = this;
        this.get('ajax').post('consignments/' + consignment.id + "/unarchive", {
          dataType: "text"
        }).then(function () {
          self.get('notify').success('Unarchived');
          self.send("getPage", self.get("page"));
        }, function (error) {
          console.log(error);
        });
      },

      getPage: function getPage(page) {
        var self = this;
        this.set("loading", true);
        this.get('ajax').request('consignments/archive?page=' + page + '&perPage=10').then(function (result) {
          self.set('page', result.page);
          self.set('totalPages', result.totalPages);
          self.set('consignments', _ember['default'].A(result.results));
          self.set("loading", false);
        }, function (error) {
          console.log(error);
          self.set("loading", false);
        });
      }

    }
  });
});