define('consumer-portal/initializers/ember-stripe-service', ['exports', 'ember', 'consumer-portal/config/environment'], function (exports, _ember, _consumerPortalConfigEnvironment) {
  exports.initialize = initialize;

  //Overide to use combined window.config and original config

  function initialize(application) {
    var finalConfig = _ember['default'].assign({}, _consumerPortalConfigEnvironment['default'], window.config);

    application.register('config:ember-stripe-service', finalConfig, { instantiate: false });
    application.inject('service:stripe', 'config', 'config:ember-stripe-service');

    if (finalConfig.LOG_STRIPE_SERVICE) {
      _ember['default'].Logger.info('StripeService: initialize');
    }

    if (!finalConfig.stripe.publishableKey) {
      throw new _ember['default'].Error('StripeService: Missing Stripe key, please set `ENV.stripe.publishableKey` in config.environment.js');
    }

    Stripe.setPublishableKey(finalConfig.stripe.publishableKey);
  }

  exports['default'] = {
    name: 'ember-stripe-stripe',
    initialize: initialize
  };
});
/* global Stripe */