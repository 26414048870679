define('consumer-portal/helpers/batch-label-url', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.extend({
    config: _ember['default'].inject.service('config'),

    compute: function compute(params) {
      var ids = params[0].map(function (c) {
        return c.id;
      }).join(",");
      var jwt = params[1];
      return this.get("config.apiURL") + "/consignments/getLabels?ids=" + ids + "&token=" + jwt;
    }
  });
});