define('consumer-portal/pods/components/bulk-printing-modal/component', ['exports', 'semantic-ui-ember/components/ui-modal', 'ember'], function (exports, _semanticUiEmberComponentsUiModal, _ember) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    session: _ember['default'].inject.service('session'),

    name: 'bulk-printing',
    classNames: ['bulk-printing'],

    completedConsignmentsCount: _ember['default'].computed('consignments', function () {
      return this.get("consignments").filter(function (c) {
        return c.status !== "pending";
      }).length;
    }),

    successfulConsignments: _ember['default'].computed('consignments', function () {
      return this.get("consignments").filter(function (c) {
        return c.status === "cached";
      });
    }),

    actions: {
      done: function done() {
        this.execute('hide');
      }
    }
  });
});