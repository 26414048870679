define('consumer-portal/pods/find-parcel/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),

    queryParams: ['locate'],
    consignment: null,
    locate: '',
    notFound: false,
    loading: false,

    actions: {
      find: function find() {
        var self = this;
        if (this.get('locate').length > 0) {
          this.set('loading', true);
          this.set('notFound', false);
          this.set('consignment', null);
          this.get('ajax').request('../../locating?token=' + self.get('locate')).then(function (consignment) {
            if (consignment === null) {
              self.set('loading', false);
              self.set('consignment', null);
              self.set('notFound', true);
            } else {
              self.set('consignment', consignment);
              self.set('loading', false);
              self.set('notFound', false);
            }
          }, function () /*error*/{
            self.set('loading', false);
            self.set('consignment', null);
            self.set('notFound', true);
          });
        } else {
          this.set('consignment', null);
          this.set('notFound', false);
        }
      }
    }
  });
});