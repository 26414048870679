define("consumer-portal/helpers/contains-tas-location", ["exports", "ember"], function (exports, _ember) {
  exports.containsTASLocation = containsTASLocation;

  function containsTASLocation(params) {
    var states = params[0].map(function (l) {
      return l.state;
    });
    return states.indexOf("TAS") > -1;
  }

  exports["default"] = _ember["default"].Helper.helper(containsTASLocation);
});