define('consumer-portal/pods/components/authenticated-main-header/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service('session'),

    tagName: 'header',
    classNames: ['authenticated-main-header'],

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      _ember['default'].$('.item.email').popup({
        popup: '.email-popup.popup',
        position: 'bottom right',
        on: 'click',
        distanceAway: -12,
        offset: -6
      });
      _ember['default'].$('.item.csv-trigger').popup({
        popup: '.csv-popup.popup',
        position: 'bottom right',
        on: 'click',
        distanceAway: -6,
        offset: 2
      });
    },

    actions: {
      signOut: function signOut() {
        this.get('session').invalidate();
      },

      closeDropdown: function closeDropdown() {
        _ember['default'].$('.item.email').popup('hide');
      }
    }

  });
});