define('consumer-portal/pods/components/authenticated-simplified-header/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service('session'),

    tagName: 'header',
    classNames: ['authenticated-simplified-header'],

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      _ember['default'].$('.email-link').popup({
        popup: '.email-popup.popup',
        position: 'bottom right',
        on: 'click',
        distanceAway: 0,
        offset: 8
      });
    },

    actions: {
      signOut: function signOut() {
        this.get('session').invalidate();
      },

      goBack: function goBack() {
        this.get("onBack")();
      },

      closeDropdown: function closeDropdown() {
        _ember['default'].$('.email-link').popup('hide');
      }
    }

  });
});