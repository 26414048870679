define('consumer-portal/shapes/consignment', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    serviceType: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    items: {
      validators: [(0, _emberCpValidations.validator)("has-many"), (0, _emberCpValidations.validator)('collection', true)]
    }
  });

  exports['default'] = _ember['default'].Object.extend(Validations, {
    serviceType: null,
    pickupType: null,
    pickup: null,
    destination: null,
    items: null,
    serviceTypes: ["standard", "same-day", "international", "next-day"],

    isPickup: _ember['default'].computed.equal("pickupType", "pickup"),
    isInternational: _ember['default'].computed.equal("serviceType", "international"),

    serviceTypeId: _ember['default'].computed('serviceType', 'serviceTypes', function () {
      return this.get("serviceType") ? this.get("serviceTypes").indexOf(this.get("serviceType")) : null;
    }),

    totalWeight: _ember['default'].computed('items.@each.weight', 'items.@each.type', 'items.@each.height', 'items.@each.length', 'items.@each.width', function () {
      //TODO convert to reduce
      var physicalWeight = 0;
      var volumetricWeight = 0;
      this.get("items").forEach(function (a) {
        physicalWeight += parseFloat(a.weight);
        volumetricWeight += parseFloat(a.height) / 100 * parseFloat(a.length) / 100 * parseFloat(a.width) / 100 * 250;
      });
      return { physicalWeight: physicalWeight, volumetricWeight: volumetricWeight };
    }),

    toJSON: function toJSON() {
      return {
        serviceType: this.get("serviceTypes").indexOf(this.get("serviceType")),
        contentDescription: "N/A",
        receiverDetails: {
          company: this.get("destination.company") && this.get("destination.company") !== "" ? this.get("destination.company") : null,
          firstName: this.get("destination.firstName"),
          lastName: this.get("destination.lastName"),
          address1: this.get("destination.address"),
          address2: null,
          suburb: this.get("destination.suburb"),
          countryCode: this.get("destination.countryCode"),
          state: this.get("destination.state"),
          postcode: this.get("destination.postcode"),
          email: this.get("destination.email"),
          phone: this.get("destination.phone"),
          saveDetails: this.get("destination.saveDetails")
        },

        isPickup: this.get("isPickup"),
        pickupStreetAddress: this.get("isPickup") ? this.get("pickup.address.address.streetAddress") : null,
        pickupSuburb: this.get("isPickup") ? this.get("pickup.address.address.suburb") : null,
        pickupState: this.get("isPickup") ? this.get("pickup.address.address.state") : null,
        pickupPostcode: this.get("isPickup") ? this.get("pickup.address.address.postcode") : null,
        pickupAfter: this.get("isPickup") && this.get("pickup.formattedTimestamp") !== "Invalid date" ? this.get("pickup.formattedTimestamp") : null,

        articles: this.get("items").map(function (a) {
          return a.toJSON();
        }),

        specialInstructions: this.get("destination.specialInstructions")
      };
    }
  });
});