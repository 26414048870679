define('consumer-portal/pods/consignments/bulk-payment/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'consumer-portal/shapes/creditCard'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _consumerPortalShapesCreditCard) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    ajax: _ember['default'].inject.service("ajax"),

    model: function model(params) {
      return this.get('ajax').post('consignments/quotes', {
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(params.consignment_ids.split(",").map(function (c) {
          return parseInt(c);
        }))
      });
    },

    setupController: function setupController(controller, model) {
      controller.set("consignments", model);
      controller.get("consignments").forEach(function (s) {
        if (s.consignment.totalPrice) {
          _ember['default'].set(s, "checked", true);
        }
      });

      this.get('ajax').request('creditAccount').then(function (result) {
        controller.set("creditAccount", result);
        if (result.status === "active") {
          controller.set("paymentType", "Account");
        } else {
          controller.set("paymentType", "Card");
        }
      }, function (error) {
        console.log(error);
      });

      this.get('ajax').request('savedPaymentMethods').then(function (results) {
        controller.set("savedCards", results);
        if (results.length > 0) {
          controller.set("existingCard", controller.get("savedCards")[0]);
          controller.set("useNewCard", false);
        } else {
          controller.set("useNewCard", true);
        }
      }, function (error) {
        console.log(error);
        controller.set("savedCards", []);
        controller.set("useNewCard", true);
      });
    },

    actions: {
      willTransition: function willTransition() {
        //They are leaving the page lets clear everything
        this.controller.set("consignments", []);
        this.controller.set("lodgedConsignments", []);
        this.controller.set("state", "consignments");
        this.controller.set("termsConditions", false);
        this.controller.set("saveCard", false);
        this.controller.set("existingCard", null);
        this.controller.set("useNewCard", false);
        this.controller.set('creditCard', _consumerPortalShapesCreditCard['default'].create(_ember['default'].getOwner(this).ownerInjection()));
        this.controller.set("submissionError", null);

        return true;
      }
    }

  });
});