define('consumer-portal/pods/signup/controller', ['exports', 'ember', 'consumer-portal/shapes/registration', 'ember-ajax/errors'], function (exports, _ember, _consumerPortalShapesRegistration, _emberAjaxErrors) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    config: _ember['default'].inject.service('config'),
    metrics: _ember['default'].inject.service(),

    registration: null,

    /* Start Config for address dropdowns */
    states: _ember['default'].A(['NSW', 'QLD', 'ACT', 'WA', 'TAS', 'SA', 'VIC', 'NT']),

    suburbUrl: _ember['default'].computed('session.data.authenticated', 'config.apiURL', {
      get: function get() {
        var jwt = this.get('session.data.authenticated.jwt');
        return this.get("config.apiURL") + '/suburbSuggestions?suburb={query}&token=' + jwt;
      }
    }),

    hearReasons: _ember['default'].A(['eBay', 'HUBBED Store', 'Courier', 'Social Media', 'Search Engine', 'Friend', 'Other']),
    expectedVolumes: _ember['default'].A(['less than 10', '10 to 50', 'more than 50']),
    errors: [],

    init: function init() {
      //Need to initialize objects here because of Ember needs to exists
      this.set('registration', _consumerPortalShapesRegistration['default'].create(_ember['default'].getOwner(this).ownerInjection()));
      _ember['default'].$.fn.search.settings.templates["suburbResult"] = function (response, fields) {
        var html = '';
        if (response[fields.results] !== undefined) {
          _ember['default'].$.each(response[fields.results], function (index, result) {
            html += '<a class="result">';
            html += '<div class="content">';
            html += '<div class="title">' + result["suburb"] + '</div>';
            html += '<div class="description">' + result["state"] + " " + result["postcode"] + '</div>';
            html += '</div>';
            html += '</a>';
          });
          return html;
        }
        return false;
      };
    },

    actions: {
      updateSuburb: function updateSuburb(value) {
        this.set("registration.address.postcode", value.postcode);
        this.set("registration.address.suburb", value.suburb);
        this.set("registration.address.state", value.state);
      },

      updateHearAbout: function updateHearAbout(value) {
        this.set("registration.hearAbout", value);
      },

      searchSuburbQuery: function searchSuburbQuery(value) {
        this.set("registration.address.suburb", value);
      },

      updateState: function updateState(value) {
        this.set("registration.address.state", value);
      },

      signup: function signup() {
        var self = this;
        this.get('ajax').post('auth/newUser', {
          contentType: 'application/json; charset=utf-8',
          data: JSON.stringify({
            company: this.get("registration.company") && this.get("registration.company") !== "" ? this.get("registration.company") : null,
            firstName: this.get("registration.firstName"),
            lastName: this.get("registration.lastName"),
            email: this.get("registration.email"),
            phone: this.get("registration.phone"),
            password: this.get("registration.password"),
            address: this.get("registration.address"),
            referredBy: this.get("registration.referredBy"),
            expectedVolume: this.get("registration.expectedVolume"),
            hearAbout: this.get("registration.hearAbout") === "Other" ? this.get("registration.hearOther") : this.get("registration.hearAbout")
          })
        }).then(function () {
          self.get("metrics").set("context.email", self.get('registration.email'));
          self.get("metrics").trackEvent({ category: "Signup", action: "Success" });
          //This is little hacky
          self.get('session').authenticate('authenticator:jwt', self.get('registration.email'), self.get('registration.password')).then(function () {
            //Redirect to hubbed thankyou
            window.location.replace("http://hubbed.com.au/hubbed---thank-you!.html");
          });
        })['catch'](function (error) {
          if ((0, _emberAjaxErrors.isBadRequestError)(error)) {
            self.set("errors", error.errors.map(function (e) {
              return e.detail;
            }));
          }
        });
      }
    }
  });
});