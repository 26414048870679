define('consumer-portal/pods/consignments/bulk-payment/controller', ['exports', 'ember', 'consumer-portal/shapes/creditCard'], function (exports, _ember, _consumerPortalShapesCreditCard) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    ajax: _ember['default'].inject.service('ajax'),
    stripe: _ember['default'].inject.service(),
    config: _ember['default'].inject.service('config'),

    queryParams: ["consignment_ids"],
    consignment_ids: null,

    consignments: [],

    validConsignmentsTotal: _ember['default'].computed("consignments.@each.consignment", function () {
      return this.get("consignments").filter(function (c) {
        return c.consignment.totalPrice;
      }).length;
    }),

    consignmentsSelected: _ember['default'].computed("consignments.@each.checked", function () {
      return this.get("consignments").filterBy("checked").length;
    }),

    consignmentsSelectedTotal: _ember['default'].computed("consignments.@each.checked", function () {
      return this.get("consignments").filterBy("checked").mapBy("consignment.totalPrice").reduce(function (a, b) {
        return a + b;
      }, 0);
    }),

    creditCard: null,
    saveCard: false,
    useNewCard: false,
    savedCards: [],
    existingCard: null,
    hasAccount: true,
    paymentType: "Account",

    printingResult: [],

    state: "consignments", //consignments, payment, complete

    init: function init() {
      this.set('creditCard', _consumerPortalShapesCreditCard['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },

    actions: {
      goToConsignment: function goToConsignment(id) {
        this.transitionToRoute("consignments.new", { queryParams: { existing_id: id } });
      },

      cancel: function cancel() {
        this.transitionToRoute("consignments.ready-to-send");
      },

      finish: function finish() {
        this.transitionToRoute("consignments.ready-to-send");
      },

      setState: function setState(state) {
        this.set("state", state);
      },

      setPaymentType: function setPaymentType(value) {
        this.set("paymentType", value);
      },

      useNewCard: function useNewCard() {
        this.set("useNewCard", true);
      },

      useSavedCard: function useSavedCard() {
        this.set("useNewCard", false);
      },

      completeTransaction: function completeTransaction() {
        var _this = this;

        var self = this;
        var card = {
          cvc: this.get("creditCard.cvc"),
          number: this.get("creditCard.number"),
          exp: this.get("creditCard.exp"),
          name: this.get("creditCard.name")
        };

        if (this.get("termsConditions") && (this.get("paymentType") === "Card" && this.get("useNewCard") && this.get("creditCard").get('validations.isValid') || this.get("paymentType") === "Card" && !this.get("useNewCard") && this.get("existingCard") || this.get("paymentType") === "Account" && this.get("creditAccount.limit") - this.get("creditAccount.currentSpend") >= this.get("consignmentsSelectedTotal"))) {
          (function () {
            _this.set("completeLoading", true);
            var request = {
              consignmentIds: _this.get("consignments").filterBy("checked").map(function (c) {
                return c.consignment.id;
              }),
              stripeToken: null,
              savedCardId: _this.get("paymentType") === "Card" && !_this.get("useNewCard") && _this.get("existingCard") ? _this.get("existingCard.id") : null,
              saveCard: _this.get("paymentType") === "Card" && _this.get("useNewCard") && _this.get("saveCard"),
              useCreditAccount: _this.get("paymentType") === "Account"
            };

            if (_this.get("paymentType") === "Card" && _this.get("useNewCard")) {
              _this.get("stripe").card.createToken(card).then(function (result) {
                request.stripeToken = result.id;
                _this.get('ajax').post('consignments/pay', {
                  contentType: 'application/json; charset=utf-8',
                  data: JSON.stringify(request)
                }).then(function (result) {
                  self.set("state", "complete");
                  self.set("completeLoading", false);
                  self.set("lodgedConsignments", result);
                }, function (error) {
                  console.log(error.errors[0].detail);
                  self.set("completeLoading", false);
                  self.set("submissionError", error.errors.map(function (e) {
                    return e.detail;
                  }));
                });
              }, function (error) {
                console.log("Error", error.errors[0].detail);
                self.set("completeLoading", false);
                self.set("submissionError", error.errors.map(function (e) {
                  return e.detail;
                }));
              });
            } else {
              _this.get('ajax').post('consignments/pay', {
                contentType: 'application/json; charset=utf-8',
                data: JSON.stringify(request)
              }).then(function (result) {
                self.set("state", "complete");
                self.set("completeLoading", false);
                self.set("lodgedConsignments", result);
              }, function (error) {
                console.log(error.errors[0].detail);
                self.set("completeLoading", false);
                self.set("submissionError", error.errors.map(function (e) {
                  return e.detail;
                }));
              });
            }
          })();
        }
      },

      printAll: function printAll() {
        var _this2 = this;

        var self = this;
        console.log(this.get("lodgedConsignments").map(function (c) {
          return c.consignment.id;
        }));
        this.get('ajax').post('consignments/getLabels/cache', {
          contentType: 'application/json; charset=utf-8',
          data: JSON.stringify(this.get("lodgedConsignments").map(function (c) {
            return c.consignment.id;
          }))
        }).then(function (result) {
          _this2.set("printingResult", result);
          _ember['default'].$('.ui.bulk-printing.modal').modal('show');
          if (result.filter(function (r) {
            return r.status === "pending";
          }).length > 0) {
            self.send("printAll");
          }
          console.log(result);
        }, function (error) {
          console.log(error);
        });
      }

    }

  });
});