define('consumer-portal/pods/components/search-address-book-modal/component', ['exports', 'ember', 'semantic-ui-ember/components/ui-modal'], function (exports, _ember, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    ajax: _ember['default'].inject.service("ajax"),
    session: _ember['default'].inject.service("session"),
    config: _ember['default'].inject.service('config'),

    name: 'search-address-book',
    classNames: ['search-address-book', 'basic', 'small'],

    addressBookUrl: _ember['default'].computed('session.data.authenticated', 'config.apiURL', {
      get: function get() {
        var jwt = this.get('session.data.authenticated.jwt');
        return this.get("config.apiURL") + '/addressBook/search?q={query}&token=' + jwt;
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      _ember['default'].$.fn.search.settings.templates["addressBookResult"] = function (response, fields) {
        var html = '';
        if (response[fields.results] !== undefined) {
          _ember['default'].$.each(response[fields.results], function (index, result) {
            html += '<a class="result">';
            html += '<div class="content">';
            html += '<div class="title">' + result["firstName"] + ' ' + result["lastName"] + '</div>';
            html += '<div class="description">' + result["address"] + ", " + result["suburb"] + ", " + result["state"] + " " + result["postcode"] + '</div>';
            html += '</div>';
            html += '</a>';
          });
          return html;
        }
        return false;
      };
    },

    actions: {
      selectValue: function selectValue(entry) {
        this.get("onSelect")(entry);
      },
      searchQuery: function searchQuery(value) {
        this.set("query", value);
      }
    }
  });
});