define("consumer-portal/pods/components/country-dropdown/component", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    ajax: _ember["default"].inject.service("ajax"),

    fullCountryList: [],
    selectedCountry: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get("ajax").request("countries").then(function (result) {
        _this.set("fullCountryList", result.results);
      });
    },

    selectedObserver: _ember["default"].observer("fullCountryList", "selected", function () {
      var _this2 = this;

      if (this.get("selectedCountry") === null || this.get("selectedCountry.code") !== this.get("selected")) {
        (function () {
          _this2.set("selectedCountry", _this2.get("fullCountryList").find(function (c) {
            return c.code === _this2.get("selected");
          }));
          var guid = _ember["default"].guidFor(_this2.get("selectedCountry"));
          setTimeout(function () {
            _ember["default"].$(".country-dropdown").dropdown("set selected", guid);
          }, 0);
        })();
      }
    }),

    selectedCountryObserver: _ember["default"].observer("selectedCountry", function () {
      if (this.get("selectedCountry") && this.get("selectedCountry.code") !== this.get("selected")) {
        this.set("selected", this.get("selectedCountry.code"));
      }
    })

  });
});