define('consumer-portal/pods/components/change-password-modal/component', ['exports', 'ember', 'semantic-ui-ember/components/ui-modal', 'ember-ajax/errors'], function (exports, _ember, _semanticUiEmberComponentsUiModal, _emberAjaxErrors) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    ajax: _ember['default'].inject.service("ajax"),

    name: 'change-password',
    classNames: ['change-password'],

    existingPassword: "",
    newPassword: "",
    confirmPassword: "",
    errors: [],

    onHide: function onHide() {
      this.set("existingPassword", "");
      this.set("newPassword", "");
      this.set("confirmPassword", "");
      this.set("errors", []);
    },

    actions: {
      confirm: function confirm() {
        var self = this;
        if (this.get("newPassword") === this.get("confirmPassword")) {
          this.get('ajax').post('auth/changePassword', {
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify({
              oldPassword: this.get("existingPassword"),
              newPassword: this.get("newPassword")
            }),
            dataType: 'text'
          }).then(function () {
            self.execute('hide');
          })['catch'](function (error) {
            if ((0, _emberAjaxErrors.isBadRequestError)(error)) {
              self.set("errors", error.errors.map(function (e) {
                return e.detail;
              }));
            }
          });
        } else {
          this.set("errors", [{ message: "Password confirmation does not match" }]);
        }
      }
    }
  });
});