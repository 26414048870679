define('consumer-portal/pods/components/consignment-details-modal/component', ['exports', 'semantic-ui-ember/components/ui-modal'], function (exports, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    name: 'consignment-details',
    classNames: ['consignment-details'],

    consignment: null,

    onHide: function onHide() {
      this.set("consignment", null);
    }
  });
});