define('consumer-portal/pods/settings/controller', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        session: _ember['default'].inject.service('session'),
        ajax: _ember['default'].inject.service('ajax'),
        notify: _ember['default'].inject.service('notify'),
        config: _ember['default'].inject.service('config'),

        addresses: [],
        savedPayments: [],

        creditAccount: null,
        creditAccountHistory: [],

        queryParams: ["state", "code"],
        state: null,
        code: null,

        ebayResponseHandler: _ember['default'].observer('state', function () {
            var _this = this;

            if (this.get("state")) {
                this.get('ajax').post('accounts/ebay/link', {
                    contentType: 'application/json; charset=utf-8',
                    data: JSON.stringify({
                        state: this.get("state"),
                        code: this.get("code")
                    })
                }).then(function (result) {
                    _this.set("state", null);
                    _this.set("code", null);
                    _this.set("ebayConfig", result);
                    _this.set("session.data.authenticated.hasEbay", true);
                    // Hack to update session permanently - see https://github.com/simplabs/ember-simple-auth/issues/926
                    var session = _this.get("session.data");
                    session["authenticated"]["hasEbay"] = true;
                    _this.get("session.store").persist(session);
                })['catch'](function () {});
            }
        }),

        ebayConfig: null,

        creditReportURL: _ember['default'].computed('session.data.authenticated', 'config.apiURL', function () {
            var jwt = this.get('session.data.authenticated.jwt');
            return this.get("config.apiURL") + '/creditAccount/history/csv?&token=' + jwt;
        }),

        actions: {
            syncEbayOrders: function syncEbayOrders() {
                var _this2 = this;

                this.get('ajax').post('acccounts/ebay/sync', {
                    dataType: 'text'
                }).then(function () {
                    _this2.get('notify').success('Successfully, synced.');
                }, function () {
                    _this2.get('notify').error('Error, syncing ebay orders.');
                });
            },

            changePassword: function changePassword() {
                _ember['default'].$('.ui.change-password.modal').modal('observeChanges', true).modal('show');
            },

            addAddress: function addAddress() {
                _ember['default'].$('.ui.add-address.modal').modal('show');
            },

            editAddress: function editAddress() {
                _ember['default'].$('.ui.edit-address.modal').modal('show');
            },

            deleteAddress: function deleteAddress(address) {
                var self = this;
                this.get('ajax')['delete']('pickupAddresses/' + address.id, {
                    dataType: 'text'
                }).then(function () {
                    self.send("refreshAddresses");
                    self.get('notify').success('Successfully, deleted address.');
                }, function (error) {
                    console.log(error);
                    self.get('notify').error('Error, deleting address.');
                });
            },

            deleteMethod: function deleteMethod(method) {
                var self = this;
                this.get('ajax')['delete']('savedPaymentMethods/' + method.id, {
                    dataType: 'text'
                }).then(function () {
                    self.send("refreshPaymentMethods");
                    self.get('notify').success('Successfully, deleted payment method.');
                }, function (error) {
                    console.log(error);
                    self.get('notify').error('Error, deleting payment method.');
                });
            },

            changeDefaultAddress: function changeDefaultAddress(address, checked) {
                var self = this;
                if (checked) {
                    this.get('ajax').post('pickupAddresses/' + address.id + '/setDefault', {
                        dataType: 'text'
                    }).then(function () {
                        self.send("refreshAddresses");
                        self.get('notify').success('Successfully, changed default address.');
                    }, function (error) {
                        console.log(error);
                        self.get('notify').error('Error, changing default address.');
                    });
                }
            },

            saveNewAddress: function saveNewAddress(address) {
                var self = this;
                this.get('ajax').post('pickupAddresses', {
                    contentType: 'application/json; charset=utf-8',
                    data: JSON.stringify(address)
                }).then(function (newAddress) {
                    console.log(newAddress);
                    self.send("refreshAddresses");
                    _ember['default'].$('.ui.add-address.modal').modal('hide');
                    self.get('notify').success('Successfully, added address.');
                }, function (error) {
                    console.log(error);
                    self.get('notify').error('Error, adding address.');
                });
            },

            refreshAddresses: function refreshAddresses() {
                var self = this;
                this.get('ajax').request('pickupAddresses').then(function (results) {
                    self.set("addresses", results);
                }, function (error) {
                    console.log(error);
                });
            },

            refreshPaymentMethods: function refreshPaymentMethods() {
                var self = this;
                this.get('ajax').request('savedPaymentMethods').then(function (results) {
                    self.set("savedPayments", results);
                }, function (error) {
                    console.log(error);
                });
            },

            requestCreditAccount: function requestCreditAccount() {
                _ember['default'].$(".ui.request-credit.modal").modal("show");
            },

            requestCreditAccountConfirm: function requestCreditAccountConfirm(abn) {
                var self = this;
                this.get('ajax').post("creditAccount", {
                    contentType: 'application/json; charset=utf-8',
                    data: JSON.stringify({ abn: abn }),
                    dataType: 'text'
                }).then(function () {
                    self.send("refreshCreditAccount");
                    _ember['default'].$(".ui.request-credit.modal").modal("hide");
                    self.get('notify').success('Successfully, requested credit account');
                }, function (error) {
                    console.log(error);
                    self.get('notify').error('Error, requesting credit account');
                });
            },

            refreshCreditAccount: function refreshCreditAccount() {
                var self = this;
                this.get('ajax').request('creditAccount').then(function (results) {
                    self.set("creditAccount", results);
                }, function (error) {
                    console.log(error);
                });
                this.send("getCreditAccountHistory", 1);
            },

            getCreditAccountHistory: function getCreditAccountHistory(page) {
                var self = this;
                this.get('ajax').request('creditAccount/history?page=' + page + '&perPage=10').then(function (result) {
                    self.set('creditAccountHistory', result);
                }, function (error) {
                    console.log(error);
                });
            }

        }
    });
});