define('consumer-portal/pods/components/add-address-modal/component', ['exports', 'ember', 'semantic-ui-ember/components/ui-modal'], function (exports, _ember, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    ajax: _ember['default'].inject.service("ajax"),
    session: _ember['default'].inject.service("session"),
    config: _ember['default'].inject.service('config'),

    name: 'add-address',
    classNames: ['add-address'],

    streetAddress: "",
    suburb: "",
    postcode: "",
    state: "",

    /* Start Config for address dropdowns */
    states: _ember['default'].A(['NSW', 'QLD', 'ACT', 'WA', 'TAS', 'SA', 'VIC', 'NT']),

    suburbUrl: _ember['default'].computed('session.data.authenticated', 'config.apiURL', {
      get: function get() {
        var jwt = this.get('session.data.authenticated.jwt');
        return this.get("config.apiURL") + '/suburbSuggestions?suburb={query}&token=' + jwt;
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      _ember['default'].$.fn.search.settings.templates["suburbResult"] = function (response, fields) {
        var html = '';
        if (response[fields.results] !== undefined) {
          _ember['default'].$.each(response[fields.results], function (index, result) {
            html += '<a class="result">';
            html += '<div class="content">';
            html += '<div class="title">' + result["suburb"] + '</div>';
            html += '<div class="description">' + result["state"] + " " + result["postcode"] + '</div>';
            html += '</div>';
            html += '</a>';
          });
          return html;
        }
        return false;
      };
    },
    /* End config */

    onHide: function onHide() {
      this.set("streetAddress", "");
      this.set("suburb", "");
      this.set("postcode", "");
      this.set("state", "");
    },

    actions: {
      updateSuburb: function updateSuburb(value) {
        this.set("postcode", value.postcode);
        this.set("suburb", value.suburb);
        this.set("state", value.state);
      },

      searchSuburbQuery: function searchSuburbQuery(value) {
        this.set("suburb", value);
      },

      updateState: function updateState(value) {
        this.set("state", value);
      },

      confirm: function confirm() {
        this.get("onSave")({
          streetAddress: this.get("streetAddress"),
          suburb: this.get("suburb"),
          postcode: this.get("postcode"),
          state: this.get("state")
        });
      }
    }
  });
});