define('consumer-portal/pods/consignments/ready-to-send/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),

    queryParams: ["page"],

    loading: false,
    page: 1,
    totalPages: 1,
    consignments: _ember['default'].A([]),

    consignmentsSelected: _ember['default'].computed("consignments.@each.checked", function () {
      return this.get("consignments").filterBy("checked").length;
    }),

    actions: {
      bulkPayment: function bulkPayment() {
        var ids = this.get("consignments").filter(function (cons) {
          return cons.checked;
        }).map(function (c) {
          return c.id;
        });
        if (ids.length > 0) {
          this.transitionToRoute("consignments.bulk-payment", { queryParams: { consignment_ids: ids } });
        } else if (this.get("consignments").length > 0) {
          this.transitionToRoute("consignments.bulk-payment", { queryParams: { consignment_ids: this.get("consignments").map(function (c) {
                return c.id;
              }) } });
        }
      },

      ebaySync: function ebaySync() {
        var _this = this;

        var self = this;
        this.set("loading", true);
        this.get('ajax').post('accounts/ebay/sync').then(function () {
          _this.send('getPage', _this.get('page'));
        }, function (error) {
          console.log(error);
          self.set("loading", false);
        });
      },

      showDetails: function showDetails(consignment) {
        this.set("showingConsignment", consignment);
        _ember['default'].$(".ui.modal.consignment-details").modal('show');
      },

      'delete': function _delete(consignmentId) {
        this.set("deleteConsignmentId", consignmentId);
        _ember['default'].$('.ui.cancel.modal').modal('show');
      },

      deleteCancel: function deleteCancel() {
        _ember['default'].$('.ui.cancel.modal').modal('hide');
      },

      deleteConfirm: function deleteConfirm() {
        var self = this;
        this.get('ajax').del('consignments/' + this.get("deleteConsignmentId")).then(function () {
          self.send('getPage', self.get('page'));
          _ember['default'].$('.ui.cancel.modal').modal('hide');
        }, function () {
          self.send('getPage', self.get('page'));
          _ember['default'].$('.ui.cancel.modal').modal('hide');
        });
      },

      getPage: function getPage(page) {
        var self = this;
        this.set("loading", true);
        this.get('ajax').request('consignments/readyToSend?page=' + page + '&perPage=10').then(function (result) {
          self.set('page', result.page);
          self.set('totalPages', result.totalPages);
          self.set('consignments', _ember['default'].A(result.results));
          self.set("loading", false);
        }, function (error) {
          console.log(error);
          self.set("loading", false);
        });
      }

    }
  });
});