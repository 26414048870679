define('consumer-portal/shapes/destination', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: {
      description: "First Name",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z' \-]*$/,
        message: 'Invalid characters'
      })]
    },
    lastName: {
      description: "Last Name",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z' \-]*$/,
        message: 'Invalid characters'
      })]
    },
    suburb: {
      description: "Suburb",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 50
      })]
    },
    state: {
      description: "State",
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: _ember['default'].computed.not("model.consignment.isInternational")
      })]
    },
    postcode: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 4,
      max: 4,
      message: 'Please enter 4 digits',
      disabled: _ember['default'].computed.equal("model.consignment.isInternational", true)
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/,
      message: 'Please double check - invalid postcode',
      disabled: _ember['default'].computed.equal("model.consignment.isInternational", true)
    })],
    address: {
      description: "Address",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 100
      }), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z0-9,#'~* _\-;+]*$/,
        message: 'Invalid characters'
      }), (0, _emberCpValidations.validator)('not-po-box')]
    },
    countryCode: {
      description: "Country",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    phone: {
      description: "Phone",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 20
      })]
    },
    company: {
      description: "Company",
      validators: [(0, _emberCpValidations.validator)('length', { max: 50 }), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z0-9,.@&#' \-+]*$/,
        message: 'Invalid characters'
      })]
    },
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      message: 'Invalid email'
    })],
    specialInstructions: {
      description: "Special Instructions",
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 140,
        disabled: _ember['default'].computed.equal("model.consignment.isInternational", true)
      }), (0, _emberCpValidations.validator)('length', {
        max: 200,
        disabled: _ember['default'].computed.not("model.consignment.isInternational")
      })]
    }
  });

  exports['default'] = _ember['default'].Object.extend(Validations, {
    firstName: null,
    lastName: null,
    email: null,
    suburb: null,
    address: null,
    postcode: null,
    phone: null,
    company: null,
    countryCode: "AU",
    saveDetails: false,
    consignment: null
  });
});