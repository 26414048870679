define('consumer-portal/validators/valid-credit-card-exp', ['exports', 'ember', 'ember-cp-validations/validators/base'], function (exports, _ember, _emberCpValidationsValidatorsBase) {

  var ValidCreditCardExp = _emberCpValidationsValidatorsBase['default'].extend({
    stripe: _ember['default'].inject.service(),

    validate: function validate(value) {
      if (value) {
        var parts = value.split(" / ");
        if (parts.length === 2 && this.get("stripe").card.validateExpiry(parts[0], parts[1])) {
          return true;
        } else {
          return "Invalid Expiry";
        }
      } else {
        return "Missing Expiry";
      }
    }
  });

  exports['default'] = ValidCreditCardExp;
});