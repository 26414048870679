define("consumer-portal/helpers/pretty-delivery-status", ["exports", "ember"], function (exports, _ember) {
    exports.prettyDeliveryStatus = prettyDeliveryStatus;

    function prettyDeliveryStatus(params) {
        var dropLocation = params[0].dropLocation;
        var value = params[0].state;
        switch (value) {
            case "notYetCheckedIn":
                if (dropLocation === null) {
                    return new _ember["default"].Handlebars.SafeString("<i class='ui home icon'></i> Attempted delivery, in transit to HUBBED location - awaiting further details from courier");
                } else {
                    return new _ember["default"].Handlebars.SafeString("<i class='ui home icon'></i> Attempted delivery, awaiting pickup at HUBBED location");
                }
                break;
            case "awaitingCustomerPickup":
                return new _ember["default"].Handlebars.SafeString("<i class='ui home icon'></i> Attempted delivery, awaiting pickup at HUBBED location");
            case "awaitingCourierPickup":
                return new _ember["default"].Handlebars.SafeString("<i class='ui history icon'></i> Expired pickup, returning to courier");
            case "customerHasPickedUp":
                return new _ember["default"].Handlebars.SafeString("<i class='ui checkmark icon'></i> Successfully delivered");
            case "courierHasPickedUp":
                return new _ember["default"].Handlebars.SafeString("<i class='ui shipping icon'></i> In transit to courier depot");
            default:
                return "Exception";
        }
    }

    exports["default"] = _ember["default"].Helper.helper(prettyDeliveryStatus);
});