define('consumer-portal/validators/valid-credit-card-cvc', ['exports', 'ember', 'ember-cp-validations/validators/base'], function (exports, _ember, _emberCpValidationsValidatorsBase) {

  var ValidCreditCardCVC = _emberCpValidationsValidatorsBase['default'].extend({
    stripe: _ember['default'].inject.service(),

    validate: function validate(value) {
      return this.get("stripe").card.validateCVC(value) ? true : "Invalid CVC";
    }
  });

  exports['default'] = ValidCreditCardCVC;
});