define('consumer-portal/shapes/registration', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: {
      description: "First Name",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z' \-]*$/,
        message: 'Invalid characters'
      })]
    },
    lastName: {
      description: "Last Name",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z' \-]*$/,
        message: 'Invalid characters'
      })]
    },
    company: {
      description: "Company",
      validators: [(0, _emberCpValidations.validator)('length', { max: 50 }), (0, _emberCpValidations.validator)('format', {
        regex: /^[a-zA-Z0-9,.@&#' \-+]*$/,
        message: 'Invalid characters'
      })]
    },
    phone: (0, _emberCpValidations.validator)('presence', true),
    password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 4,
      max: 50
    })],
    passwordConfirmation: (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: 'Password does not match'
    }),
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      message: 'Invalid email'
    })],
    "address.streetAddress": [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /^[a-zA-Z0-9,#'~* _\-;+]*$/,
      message: 'Invalid characters'
    }), (0, _emberCpValidations.validator)('not-po-box')],
    "address.suburb": (0, _emberCpValidations.validator)('presence', true),
    "address.postcode": [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 4,
      max: 4,
      message: 'Please enter 4 digits'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/,
      message: 'Please double check - invalid postcode'
    })],
    "address.state": (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = _ember['default'].Object.extend(Validations, {
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    company: null,
    phone: null,
    address: {
      streetAddress: null,
      suburb: null,
      postcode: null,
      state: null
    },
    hearAbout: null,
    hearOther: null,
    referredBy: null,
    expectedVolume: null
  });
});