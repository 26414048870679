define('consumer-portal/pods/consignments/batch-upload/controller', ['exports', 'ember'], function (exports, _ember) {

  /* global Dropzone */

  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    config: _ember['default'].inject.service('config'),

    dropzoneURL: _ember['default'].computed('config.apiURL', {
      get: function get() {
        return this.get("config.apiURL") + '/consignments/bulkUpload';
      }
    }),

    dropzoneHeaders: _ember['default'].computed('session.data.authenticated', {
      get: function get() {
        var headers = {};
        var jwt = this.get('session.data.authenticated.jwt');
        if (jwt) {
          headers['Authorization'] = "Bearer " + jwt;
        }
        return headers;
      }
    }),

    actions: {
      successEventCSV: function successEventCSV() {
        this.set("errors", null);
        Dropzone.forElement('#csv-upload').removeAllFiles();
        this.transitionToRoute("consignments.ready-to-send");
      },

      failureEventCSV: function failureEventCSV(a, responseJson) {
        Dropzone.forElement('#csv-upload').removeAllFiles();
        this.set("errors", responseJson);
      }
    }

  });
});