define('consumer-portal/shapes/pickup', ['exports', 'ember', 'ember-cp-validations', 'moment'], function (exports, _ember, _emberCpValidations, _moment) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    date: {
      description: "Date",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('date', {
        onOrAfter: 'now',
        precision: 'day',
        format: 'DD-MM-YY',
        errorFormat: 'DD-MM-YY',
        isWarning: true
      })]
    },
    address: {
      description: "Address",
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    time: {
      description: "Time",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('future-time', {
        isWarning: true
      })]
    }
  });

  exports['default'] = _ember['default'].Object.extend(Validations, {
    date: null,
    time: null,
    address: null,

    formattedTimestamp: _ember['default'].computed('date', 'time', function () {
      if (this.get('date') && this.get("time")) {
        return (0, _moment['default'])(this.get('date') + " " + this.get("time"), "DD-MM-YY h:mmA").format();
      }
      return null;
    })
  });
});