define('consumer-portal/pods/index/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        session: _ember['default'].inject.service("session"),

        beforeModel: function beforeModel(transition) {
            transition.abort();
            if (transition.queryParams.locate) {
                window.location.replace('https://staging.hubbed.com.au/find-parcel?locate=' + transition.queryParams.locate);
                //this.transitionTo('find-parcel');
            } else {
                    window.location.replace('https://try.sendle.com/en-au/partners/hubbed');
                }
        }
    });
});