define('consumer-portal/pods/components/edit-address-modal/component', ['exports', 'ember', 'semantic-ui-ember/components/ui-modal'], function (exports, _ember, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    ajax: _ember['default'].inject.service("ajax"),

    name: 'edit-address',
    classNames: ['edit-address'],

    existingPassword: "",
    newPassword: "",
    confirmPassword: "",

    onHide: function onHide() {
      this.set("existingPassword", "");
      this.set("newPassword", "");
      this.set("confirmPassword", "");
    },

    actions: {
      confirm: function confirm() {}
    }
  });
});