define('consumer-portal/pods/components/tracking-row/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    session: _ember['default'].inject.service('session'),
    ajax: _ember['default'].inject.service(),

    tagName: 'tr',

    status: null,
    eta: null,

    status_colour: _ember['default'].computed('status', function () {
      switch (this.get("status")) {
        case "In Transit":
          return "blue";
        case "Delivered":
          return "green";
        default:
          return "";
      }
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('ajax').request('consignments/' + this.get("consignment.id") + '/track').then(function (result) {
        _this.set("status", result.map(function (r) {
          return r.str;
        }).join("\n"));
        _this.set("eta", result.map(function (r) {
          return r.eta;
        }).join("\n"));
      }, function (error) {
        _this.set("status", "Processing");
        console.log(error);
      });
    },

    actions: {
      showDetails: function showDetails() {
        this.get("onShowDetails")();
      },

      archive: function archive() {
        this.get("onArchive")();
      }
    }

  });
});