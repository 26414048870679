define('consumer-portal/pods/address-book/controller', ['exports', 'ember', 'consumer-portal/shapes/destination'], function (exports, _ember, _consumerPortalShapesDestination) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    ajax: _ember['default'].inject.service('ajax'),
    notify: _ember['default'].inject.service('notify'),

    queryParams: ["page"],

    loading: false,
    page: 1,
    totalPages: 1,
    addresses: _ember['default'].A([]),

    init: function init() {
      this.set('newEntry', _consumerPortalShapesDestination['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },

    actions: {

      addAddress: function addAddress() {
        this.set('newEntry', _consumerPortalShapesDestination['default'].create(_ember['default'].getOwner(this).ownerInjection()));
        _ember['default'].$('.ui.add-address-book.modal').modal('show');
      },

      editAddress: function editAddress(entry) {
        this.set('editingEntry', _consumerPortalShapesDestination['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
          id: entry.id,
          firstName: entry.firstName,
          lastName: entry.lastName,
          company: entry.companyName,
          phone: entry.phone,
          email: entry.email,
          address: entry.address,
          suburb: entry.suburb,
          postcode: entry.postcode,
          state: entry.state
        }));
        _ember['default'].$('.ui.edit-address-book.modal').modal('show');
      },

      deleteAddress: function deleteAddress(entry) {
        this.set('deleteEntry', entry);
        _ember['default'].$('.ui.delete-address-book.modal').modal('show');
      },

      deleteAddressConfirm: function deleteAddressConfirm(entry) {
        var self = this;
        this.get('ajax')['delete']('addressBook/' + entry.id, {
          dataType: 'text'
        }).then(function () {
          self.send("getPage", 1);
          _ember['default'].$('.ui.delete-address-book.modal').modal('hide');
          self.get('notify').success('Successfully, deleted address.');
        }, function (error) {
          console.log(error);
          self.get('notify').error('Error, deleting address.');
        });
      },

      saveNewAddress: function saveNewAddress(address) {
        var self = this;
        this.get('ajax').post('addressBook', {
          contentType: 'application/json; charset=utf-8',
          data: JSON.stringify(address)
        }).then(function (newAddress) {
          console.log(newAddress);
          self.send("getPage", 1);
          _ember['default'].$('.ui.add-address-book.modal').modal('hide');
          self.get('notify').success('Successfully, added address.');
        }, function (error) {
          console.log(error);
          self.get('notify').error('Error, adding address.');
        });
      },

      saveEditAddress: function saveEditAddress(address) {
        var self = this;
        this.get('ajax').post('addressBook/' + address.id, {
          contentType: 'application/json; charset=utf-8',
          data: JSON.stringify(address),
          dataType: 'text'
        }).then(function () {
          self.send("getPage", 1);
          _ember['default'].$('.ui.edit-address-book.modal').modal('hide');
          self.get('notify').success('Successfully, edited address.');
        }, function (error) {
          console.log(error);
          self.get('notify').error('Error, edited address.');
        });
      },

      getPage: function getPage(page) {
        var self = this;
        this.set("loading", true);
        this.get('ajax').request('addressBook?page=' + page + '&perPage=10').then(function (result) {
          self.set('page', result.page);
          self.set('totalPages', result.totalPages);
          self.set('addresses', _ember['default'].A(result.results));
          self.set("loading", false);
        }, function (error) {
          console.log(error);
          self.set("loading", false);
        });
      }

    }
  });
});