define('consumer-portal/pods/components/add-address-book-modal/component', ['exports', 'ember', 'semantic-ui-ember/components/ui-modal'], function (exports, _ember, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    ajax: _ember['default'].inject.service("ajax"),
    session: _ember['default'].inject.service("session"),
    config: _ember['default'].inject.service('config'),

    name: 'add-address-book',
    classNames: ['add-address-book'],

    /* Start Config for address dropdowns */
    states: _ember['default'].A(['NSW', 'QLD', 'ACT', 'WA', 'TAS', 'SA', 'VIC', 'NT']),

    suburbUrl: _ember['default'].computed('session.data.authenticated', 'config.apiURL', {
      get: function get() {
        var jwt = this.get('session.data.authenticated.jwt');
        return this.get("config.apiURL") + '/suburbSuggestions?suburb={query}&token=' + jwt;
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      _ember['default'].$.fn.search.settings.templates["suburbResult"] = function (response, fields) {
        var html = '';
        if (response[fields.results] !== undefined) {
          _ember['default'].$.each(response[fields.results], function (index, result) {
            html += '<a class="result">';
            html += '<div class="content">';
            html += '<div class="title">' + result["suburb"] + '</div>';
            html += '<div class="description">' + result["state"] + " " + result["postcode"] + '</div>';
            html += '</div>';
            html += '</a>';
          });
          return html;
        }
        return false;
      };
    },
    /* End config */

    actions: {
      updateSuburb: function updateSuburb(value) {
        this.set("entry.postcode", value.postcode);
        this.set("entry.suburb", value.suburb);
        this.set("entry.state", value.state);
      },

      searchSuburbQuery: function searchSuburbQuery(value) {
        this.set("entry.suburb", value);
      },

      updateState: function updateState(value) {
        this.set("entry.state", value);
      },

      confirm: function confirm() {
        this.get("onSave")({
          firstName: this.get("entry.firstName"),
          lastName: this.get("entry.lastName"),
          companyName: this.get("entry.company"),
          phone: this.get("entry.phone"),
          email: this.get("entry.email"),
          address: this.get("entry.address"),
          suburb: this.get("entry.suburb"),
          postcode: this.get("entry.postcode"),
          state: this.get("entry.state")
        });
      }
    }
  });
});