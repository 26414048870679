define('consumer-portal/pods/reset-password/controller', ['exports', 'ember', 'ember-ajax/errors'], function (exports, _ember, _emberAjaxErrors) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    ajax: _ember['default'].inject.service('ajax'),

    queryParams: ["token"],

    password: "",
    token: "",
    errors: [],

    actions: {
      submit: function submit() {
        var self = this;
        this.get('ajax').post('auth/resetPassword', {
          contentType: 'application/json; charset=utf-8',
          data: JSON.stringify({
            token: this.get("token"),
            password: this.get("password")
          }),
          dataType: "text"
        }).then(function () {
          self.set("success", true);
          self.set("errors", []);
        })['catch'](function (error) {
          if ((0, _emberAjaxErrors.isBadRequestError)(error)) {
            self.set("errors", error.errors.map(function (e) {
              return e.detail;
            }));
          }
        });
      }
    }
  });
});