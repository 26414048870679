define('consumer-portal/validators/future-time', ['exports', 'ember-cp-validations/validators/base', 'moment'], function (exports, _emberCpValidationsValidatorsBase, _moment) {

  var FutureTime = _emberCpValidationsValidatorsBase['default'].extend({
    validate: function validate(value, options, model) {
      if (value && model.date) {
        if ((0, _moment['default'])(model.date + ' ' + value, "DD-MM-YY h:mma").isAfter()) {
          return true;
        } else {
          return "Time must be after current time";
        }
      } else {
        return "Invalid";
      }
    }
  });

  FutureTime.reopenClass({
    getDependentsFor: function getDependentsFor() {
      return ['date'];
    }
  });

  exports['default'] = FutureTime;
});