define('consumer-portal/pods/settings/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
        ajax: _ember['default'].inject.service("ajax"),

        setupController: function setupController(controller) {
            this.get('ajax').request('accounts/ebay').then(function (result) {
                controller.set("ebayConfig", result);
            }, function (error) {
                console.log(error);
            });

            this.get('ajax').request('pickupAddresses').then(function (results) {
                controller.set("addresses", results);
            }, function (error) {
                console.log(error);
            });

            this.get('ajax').request('savedPaymentMethods').then(function (results) {
                controller.set("savedPayments", results);
            }, function (error) {
                console.log(error);
            });
            controller.send("refreshCreditAccount");
            controller.send("getCreditAccountHistory", 1);
        }
    });
});