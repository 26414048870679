define('consumer-portal/app', ['exports', 'ember', 'consumer-portal/resolver', 'ember-load-initializers', 'consumer-portal/config/environment'], function (exports, _ember, _consumerPortalResolver, _emberLoadInitializers, _consumerPortalConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _consumerPortalConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _consumerPortalConfigEnvironment['default'].podModulePrefix,
    Resolver: _consumerPortalResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _consumerPortalConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});