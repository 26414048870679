define('consumer-portal/pods/components/delete-address-book-modal/component', ['exports', 'semantic-ui-ember/components/ui-modal'], function (exports, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    name: 'delete-address-book',
    classNames: ['delete-address-book'],

    actions: {
      confirm: function confirm() {
        this.get("onDelete")(this.get("entry"));
      }
    }
  });
});