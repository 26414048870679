define('consumer-portal/pods/consignments/new/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'consumer-portal/shapes/itemDetails', 'consumer-portal/shapes/destination', 'consumer-portal/shapes/pickup', 'consumer-portal/shapes/creditCard', 'moment'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _consumerPortalShapesItemDetails, _consumerPortalShapesDestination, _consumerPortalShapesPickup, _consumerPortalShapesCreditCard, _moment) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
        ajax: _ember['default'].inject.service("ajax"),
        queryParams: {
            existing_id: {
                // refreshModel: true
            }
        },

        model: function model(params) {
            if (params.existing_id) {
                return this.get('ajax').request('consignments/' + params.existing_id);
            } else {
                return null;
            }
        },

        setupController: function setupController(controller, model) {
            var _this = this;

            controller.set("promoCode", null);
            controller.set("promoCodeDetails", null);
            this.get('ajax').request('creditAccount').then(function (result) {
                controller.set("creditAccount", result);
                if (result.status === "active") {
                    controller.set("paymentType", "Account");
                } else {
                    controller.set("paymentType", "Card");
                }
            }, function (error) {
                console.log(error);
            });

            this.get('ajax').request('savedPaymentMethods').then(function (results) {
                controller.set("savedCards", results);
                if (results.length > 0) {
                    controller.set("existingCard", controller.get("savedCards")[0]);
                    controller.set("useNewCard", false);
                } else {
                    controller.set("useNewCard", true);
                }
            }, function (error) {
                console.log(error);
                controller.set("savedCards", []);
                controller.set("useNewCard", true);
            });
            controller.set("consignment.serviceType", "standard");
            controller.set("fullConsignment", model);
            if (model) {
                //Set up all the existing values
                controller.set("consignment.serviceType", controller.get("serviceTypes")[model.serviceType]);
                controller.set("consignment.destination.address", model.receiverDetails.address1);
                controller.set("consignment.destination.suburb", model.receiverDetails.suburb);
                controller.set("consignment.destination.postcode", model.receiverDetails.postcode);
                controller.set("consignment.destination.state", model.receiverDetails.state);
                controller.set("consignment.destination.countryCode", model.receiverDetails.countryCode);
                controller.set("consignment.destination.firstName", model.receiverDetails.firstName);
                controller.set("consignment.destination.lastName", model.receiverDetails.lastName);
                controller.set("consignment.destination.company", model.receiverDetails.company);
                controller.set("consignment.destination.phone", model.receiverDetails.phone);
                controller.set("consignment.destination.email", model.receiverDetails.email);
                controller.set("consignment.destination.specialInstructions", model.specialInstructions);

                if (model.isPickup) {
                    controller.set("consignment.pickupType", "pickup");
                    controller.set("consignment.pickup.time", (0, _moment['default'])(model.pickupAfter).format("h:mmA"));
                    controller.set("consignment.pickup.date", (0, _moment['default'])(model.pickupAfter).format("DD-MM-YY"));
                } else {
                    controller.set("consignment.pickupType", "dropoff");
                    controller.set("consignment.pickup.time", null);
                    controller.set("consignment.pickup.date", null);
                }

                controller.set("consignment.items", model.articles.map(function (a) {
                    return _consumerPortalShapesItemDetails['default'].create(_ember['default'].getOwner(_this).ownerInjection(), {
                        consignment: controller.get("consignment"),
                        type: controller.get("consignment.isInternational") ? "Custom" : a.packagingType,
                        product: controller.get("consignment.isInternational") ? null : a.packagingProduct,
                        packagingType: a.ratePackagingType,
                        length: parseInt(a.length),
                        height: parseInt(a.height),
                        width: parseInt(a.width),
                        weight: parseFloat(a.physicalWeight),
                        cost: a.centsValue / 100,
                        skuNumber: a.skuNumber,
                        description: a.description
                    });
                }));
                if (model.articles.length === 0) {
                    this.controller.get("consignment.items").pushObject(_consumerPortalShapesItemDetails['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
                        type: controller.get("consignment.isInternational") ? "Custom" : "HUBBED",
                        product: controller.get("consignment.isInternational") ? null : "Small Satchel"
                    }));
                }

                controller.set("consignment.pickup.address", { address: {} });
                controller.set("consignment.pickup.address.address.streetAddress", model.pickupStreetAddress);
                controller.set("consignment.pickup.address.address.suburb", model.pickupSuburb);
                controller.set("consignment.pickup.address.address.state", model.pickupState);
                controller.set("consignment.pickup.address.address.postcode", model.pickupPostcode);

                var serviceDetailsValid = ["standard", "international"].includes(controller.get("consignment.serviceType"));
                controller.send("refreshAddresses");

                if (serviceDetailsValid) {
                    controller.set("state", "consignmentDetails");
                } else {
                    controller.set("state", "serviceType");
                }
            } else {
                controller.send("refreshAddresses");
            }
        },

        actions: {
            reloadAll: function reloadAll() {
                this.refresh();
            },

            willTransition: function willTransition() {
                //They are leaving the page lets clear everything
                this.controller.set("fullConsignment", null);
                this.controller.set("termsConditions", false);
                this.controller.set("saveCard", false);
                this.controller.set("useNewCard", false);
                this.controller.set("existing_id", null);
                this.controller.set("consignment.destination", _consumerPortalShapesDestination['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
                    consignment: this.controller.get("consignment")
                }));
                this.controller.set('creditCard', _consumerPortalShapesCreditCard['default'].create(_ember['default'].getOwner(this).ownerInjection()));
                this.controller.set('consignment.pickup', _consumerPortalShapesPickup['default'].create(_ember['default'].getOwner(this).ownerInjection()));
                this.controller.set("consignment.pickupType", "pickup");
                this.controller.set("dropOffResult", {
                    searched: false,
                    lat: -33.8590647,
                    lon: 151.1724718,
                    locations: []
                });
                this.controller.set("dropOffPostcode", null);
                this.controller.set("state", "serviceType");
                this.controller.set("consignment.serviceType", "standard");
                this.controller.set("consignment.items", [_consumerPortalShapesItemDetails['default'].create(_ember['default'].getOwner(this).ownerInjection())]);
                this.controller.set("quoteErrors", null);
                this.controller.set("submissionError", null);
                this.controller.set("pickupAddresses", []);
                this.controller.set("recentAddressAddId", null);

                return true;
            }
        }
    });
});