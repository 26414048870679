define("consumer-portal/pods/index/controller", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        tab: "sendParcel",
        trackNumber: "",

        actions: {
            setTab: function setTab(tab) {
                this.set("tab", tab);
            },

            findParcel: function findParcel() {
                this.transitionToRoute('find-parcel', { queryParams: { locate: this.get("trackNumber") } });
            }
        }
    });
});