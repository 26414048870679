define("consumer-portal/pods/components/domestic-item-selection/component", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    maxWeight: 24.99,
    maxVolume: 24.99,

    actions: {
      setItemType: function setItemType(item, type) {
        _ember["default"].set(item, "type", type);
        if (type === "HUBBED") {
          _ember["default"].setProperties(item, {
            product: "Small Satchel",
            length: 30,
            width: 20,
            height: 1,
            weight: 0.5,
            packagingType: "satchel"
          });
        } else {
          _ember["default"].setProperties(item, {
            product: null,
            length: 10,
            width: 20,
            height: 30,
            packagingType: "box"
          });
        }
      },

      setPackagingType: function setPackagingType(item, type) {
        _ember["default"].set(item, "packagingType", type);
      },

      setProductType: function setProductType(item, product, length, width, height, weight, packagingType) {
        _ember["default"].setProperties(item, {
          product: product,
          length: length,
          width: width,
          height: height,
          weight: weight,
          packagingType: packagingType
        });
      }
    }
  });
});