define('consumer-portal/initializers/page-tracking', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('router:main', 'metrics', 'service:metrics');
  }

  exports['default'] = {
    name: 'page-tracking',
    initialize: initialize
  };
});