define("consumer-portal/validators/not-po-box", ["exports", "ember-cp-validations/validators/base"], function (exports, _emberCpValidationsValidatorsBase) {

  var NotPOBox = _emberCpValidationsValidatorsBase["default"].extend({
    validate: function validate(value) {
      var tests = ["po box", "p.o. box", "post office box", "locked bag", "parcel locker"];
      if (value) {
        var lowered = value.toLowerCase();
        for (var i = 0; i < tests.length; i++) {
          if (lowered.indexOf(tests[i]) >= 0) {
            return "PO Boxes are not allowed";
          }
        }
      }
      return true;
    }
  });

  exports["default"] = NotPOBox;
});