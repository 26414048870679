define("consumer-portal/pods/consignments/ready-to-send/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 8
            },
            "end": {
              "line": 3,
              "column": 179
            }
          },
          "moduleName": "consumer-portal/pods/consignments/ready-to-send/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "ui right floated hubbed-orange button");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "send icon");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("Send new parcel");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 8
            },
            "end": {
              "line": 9,
              "column": 8
            }
          },
          "moduleName": "consumer-portal/pods/consignments/ready-to-send/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "hubbed-send-button");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "ui right floated hubbed-orange basic button");
          var el3 = dom.createElement("i");
          dom.setAttribute(el3, "class", "refresh icon");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("Ebay Sync");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element6);
          return morphs;
        },
        statements: [["element", "action", ["ebaySync"], [], ["loc", [null, [8, 13], [8, 34]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 8
            },
            "end": {
              "line": 11,
              "column": 187
            }
          },
          "moduleName": "consumer-portal/pods/consignments/ready-to-send/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "ui right floated hubbed-orange basic button");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "upload icon");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("Upload");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 8
            },
            "end": {
              "line": 17,
              "column": 8
            }
          },
          "moduleName": "consumer-portal/pods/consignments/ready-to-send/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "ui loading segment");
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.6.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 40,
                    "column": 21
                  },
                  "end": {
                    "line": 40,
                    "column": 130
                  }
                },
                "moduleName": "consumer-portal/pods/consignments/ready-to-send/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Send");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 16
                },
                "end": {
                  "line": 45,
                  "column": 16
                }
              },
              "moduleName": "consumer-portal/pods/consignments/ready-to-send/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(", ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(", ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "right aligned");
              var el3 = dom.createTextNode("\n                     ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3, "class", "compact ui button");
              var el4 = dom.createTextNode("Details");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                      ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              dom.setAttribute(el3, "class", "compact ui basic red icon button");
              var el4 = dom.createElement("i");
              dom.setAttribute(el4, "class", "trash icon");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [7]);
              var element2 = dom.childAt(element0, [9]);
              var element3 = dom.childAt(element0, [11]);
              var element4 = dom.childAt(element3, [3]);
              var element5 = dom.childAt(element3, [5]);
              var morphs = new Array(13);
              morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
              morphs[3] = dom.createMorphAt(element1, 0, 0);
              morphs[4] = dom.createMorphAt(element1, 2, 2);
              morphs[5] = dom.createMorphAt(element1, 4, 4);
              morphs[6] = dom.createMorphAt(element1, 6, 6);
              morphs[7] = dom.createMorphAt(element1, 8, 8);
              morphs[8] = dom.createMorphAt(element2, 0, 0);
              morphs[9] = dom.createMorphAt(element2, 2, 2);
              morphs[10] = dom.createMorphAt(element3, 1, 1);
              morphs[11] = dom.createElementMorph(element4);
              morphs[12] = dom.createElementMorph(element5);
              return morphs;
            },
            statements: [["inline", "ui-checkbox", [], ["checked", ["subexpr", "@mut", [["get", "cons.checked", ["loc", [null, [34, 42], [34, 54]]]]], [], []], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "cons.checked", ["loc", [null, [34, 77], [34, 89]]]]], [], ["loc", [null, [34, 72], [34, 90]]]]], [], ["loc", [null, [34, 64], [34, 91]]]]], ["loc", [null, [34, 20], [34, 93]]]], ["inline", "moment-format", [["get", "cons.createdAt", ["loc", [null, [35, 36], [35, 50]]]], "DD/MM HH:mm"], [], ["loc", [null, [35, 20], [35, 67]]]], ["content", "cons.articles.length", ["loc", [null, [36, 20], [36, 44]]]], ["content", "cons.receiverDetails.address1", ["loc", [null, [37, 20], [37, 53]]]], ["content", "cons.receiverDetails.suburb", ["loc", [null, [37, 55], [37, 86]]]], ["content", "cons.receiverDetails.state", ["loc", [null, [37, 87], [37, 117]]]], ["content", "cons.receiverDetails.postcode", ["loc", [null, [37, 118], [37, 151]]]], ["content", "cons.receiverDetails.countryCode", ["loc", [null, [37, 153], [37, 189]]]], ["content", "cons.receiverDetails.firstName", ["loc", [null, [38, 20], [38, 54]]]], ["content", "cons.receiverDetails.lastName", ["loc", [null, [38, 55], [38, 88]]]], ["block", "link-to", ["consignments.new", ["subexpr", "query-params", [], ["existing_id", ["get", "cons.id", ["loc", [null, [40, 77], [40, 84]]]]], ["loc", [null, [40, 51], [40, 85]]]]], ["class", "compact ui basic green button"], 0, null, ["loc", [null, [40, 21], [40, 142]]]], ["element", "action", ["showDetails", ["get", "cons", ["loc", [null, [41, 79], [41, 83]]]]], [], ["loc", [null, [41, 56], [41, 85]]]], ["element", "action", ["delete", ["get", "cons.id", ["loc", [null, [42, 89], [42, 96]]]]], [], ["loc", [null, [42, 71], [42, 98]]]]],
            locals: ["cons"],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 45,
                  "column": 16
                },
                "end": {
                  "line": 47,
                  "column": 16
                }
              },
              "moduleName": "consumer-portal/pods/consignments/ready-to-send/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "colspan", "6");
              dom.setAttribute(el2, "style", "text-align:center");
              var el3 = dom.createTextNode("You have no pending consignments");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.6.2",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 20
              },
              "end": {
                "line": 52,
                "column": 20
              }
            },
            "moduleName": "consumer-portal/pods/consignments/ready-to-send/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("table");
            dom.setAttribute(el1, "class", "ui very compact table");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("thead");
            dom.setAttribute(el2, "class", "full-width");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("tr");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            dom.setAttribute(el4, "class", "collapsed");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Uploaded");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Items");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Destination");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            var el5 = dom.createTextNode("Recipient");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("th");
            dom.setAttribute(el4, "class", "right aligned");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("tbody");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n       ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["block", "each", [["get", "consignments", ["loc", [null, [32, 24], [32, 36]]]]], [], 0, 1, ["loc", [null, [32, 16], [47, 25]]]], ["inline", "pagination-component", [], ["page", ["subexpr", "@mut", [["get", "page", ["loc", [null, [50, 35], [50, 39]]]]], [], []], "totalPages", ["subexpr", "@mut", [["get", "totalPages", ["loc", [null, [50, 51], [50, 61]]]]], [], []], "getPageAction", ["subexpr", "action", ["getPage"], [], ["loc", [null, [50, 76], [50, 94]]]]], ["loc", [null, [50, 7], [50, 97]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 55,
                  "column": 20
                },
                "end": {
                  "line": 55,
                  "column": 177
                }
              },
              "moduleName": "consumer-portal/pods/consignments/ready-to-send/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "ui hubbed-orange button");
              var el2 = dom.createElement("i");
              dom.setAttribute(el2, "class", "send icon");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("Send new parcel");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.6.2",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 20
              },
              "end": {
                "line": 57,
                "column": 12
              }
            },
            "moduleName": "consumer-portal/pods/consignments/ready-to-send/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "ui very padded secondary center aligned segment");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            dom.setAttribute(el2, "class", "bold-message");
            var el3 = dom.createTextNode("You have no pending parcels. To send a parcel, press the \"Send new parcel\" button.");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 3, 3);
            return morphs;
          },
          statements: [["block", "link-to", ["consignments.new"], ["class", "hubbed-send-button", "activeClass", ""], 0, null, ["loc", [null, [55, 20], [55, 189]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 8
            },
            "end": {
              "line": 58,
              "column": 20
            }
          },
          "moduleName": "consumer-portal/pods/consignments/ready-to-send/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "consignments", ["loc", [null, [18, 26], [18, 38]]]]], [], 0, 1, ["loc", [null, [18, 20], [57, 19]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.6.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 67,
            "column": 0
          }
        },
        "moduleName": "consumer-portal/pods/consignments/ready-to-send/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "authenticated-main-body");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "consignments-ready-to-send-page");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "class", "hubbed-send-button");
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "ui right floated hubbed-orange basic button");
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "dollar icon");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("Bulk Payment (");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(")");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        dom.setAttribute(el3, "class", "hubbed-page-heading");
        var el4 = dom.createTextNode("Pending");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n        ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element7 = dom.childAt(fragment, [0, 1]);
        var element8 = dom.childAt(element7, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(element7, 1, 1);
        morphs[1] = dom.createElementMorph(element8);
        morphs[2] = dom.createMorphAt(dom.childAt(element8, [0]), 2, 2);
        morphs[3] = dom.createMorphAt(element7, 5, 5);
        morphs[4] = dom.createMorphAt(element7, 7, 7);
        morphs[5] = dom.createMorphAt(element7, 11, 11);
        morphs[6] = dom.createMorphAt(element7, 13, 13);
        morphs[7] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["block", "link-to", ["consignments.new"], ["class", "hubbed-send-button", "activeClass", ""], 0, null, ["loc", [null, [3, 8], [3, 191]]]], ["element", "action", ["bulkPayment"], [], ["loc", [null, [5, 11], [5, 35]]]], ["inline", "if", [["subexpr", "gt", [["get", "consignmentsSelected", ["loc", [null, [5, 170], [5, 190]]]], 0], [], ["loc", [null, [5, 166], [5, 193]]]], "selected", "all"], [], ["loc", [null, [5, 161], [5, 212]]]], ["block", "if", [["get", "session.data.authenticated.hasEbay", ["loc", [null, [7, 14], [7, 48]]]]], [], 1, null, ["loc", [null, [7, 8], [9, 15]]]], ["block", "link-to", ["consignments.batch-upload"], ["class", "hubbed-send-button", "activeClass", ""], 2, null, ["loc", [null, [11, 8], [11, 199]]]], ["block", "if", [["get", "loading", ["loc", [null, [15, 14], [15, 21]]]]], [], 3, 4, ["loc", [null, [15, 8], [58, 27]]]], ["inline", "consignment-details-modal", [], ["consignment", ["subexpr", "@mut", [["get", "showingConsignment", ["loc", [null, [60, 48], [60, 66]]]]], [], []]], ["loc", [null, [60, 8], [60, 68]]]], ["inline", "cancel-modal", [], ["onDiscard", ["subexpr", "action", ["deleteConfirm"], [], ["loc", [null, [66, 25], [66, 49]]]], "onKeep", ["subexpr", "action", ["deleteCancel"], [], ["loc", [null, [66, 57], [66, 80]]]]], ["loc", [null, [66, 0], [66, 82]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});