define('consumer-portal/pods/forgot-password/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    ajax: _ember['default'].inject.service('ajax'),

    email: "",

    actions: {
      submit: function submit() {
        var self = this;
        this.get('ajax').post('auth/sendResetPasswordEmail', {
          contentType: 'application/json; charset=utf-8',
          data: JSON.stringify({ email: this.get("email") }),
          dataType: "text"
        }).then(function () {
          self.set("success", true);
        }, function () {
          self.set("success", true);
        });
      }
    }
  });
});