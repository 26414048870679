define('consumer-portal/router', ['exports', 'ember', 'consumer-portal/config/environment'], function (exports, _ember, _consumerPortalConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _consumerPortalConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('find-parcel', { path: '/track-your-parcel' });
    this.route('find-hubbstation', { path: '/find-our-station' });
    this.route('collect-parcel', { path: '/collect-your-parcel' });
    this.route('dhl-find-parcel', { path: '/find-dhl-parcel' });

    /**this.route('login');
    this.route('forgot-password');
    this.route('reset-password');
    this.route('signup');
    this.route('find-parcel');
    this.route('collect-parcel');
    this.route('dhl-find-parcel');
    this.route('find-hubbstation');
    this.route('address-book');
    this.route('settings');
    this.route('consignments', function() {
      this.route('send-parcel');
      this.route('ready-to-send');
      this.route('new');
      this.route('bulk-payment');
      this.route('batch-upload');
      this.route('tracking');
      this.route('archive');
    });**/
    this.route('privacy-policy');
    this.route('not-found', { path: '/*path' });
  });

  exports['default'] = Router;
});