define('consumer-portal/authenticators/jwt', ['exports', 'ember', 'rsvp', 'ember-simple-auth/authenticators/base', 'npm:jwt-decode'], function (exports, _ember, _rsvp, _emberSimpleAuthAuthenticatorsBase, _npmJwtDecode) {
  exports['default'] = _emberSimpleAuthAuthenticatorsBase['default'].extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),

    refreshLeeway: 10000, //Refresh 10 seconds before expiry
    refreshTokenTimeout: null,

    restore: function restore(data) {
      var _this = this;

      var dataObject = _ember['default'].Object.create(data);

      return new _rsvp['default'].Promise(function (resolve, reject) {
        var now = _this.getCurrentTime();
        var jwtPayload = (0, _npmJwtDecode['default'])(dataObject.get('jwt'));
        var expiresAt = jwtPayload.exp * 1000;

        if (expiresAt > now + _this.refreshLeeway) {
          var wait = expiresAt - now - _this.refreshLeeway;

          if (wait > 0) {
            _this.scheduleJWTRefresh(expiresAt);
            resolve(data);
          } else {
            reject(new Error('unable to refresh JWT'));
          }
        } else {
          reject(new Error("JWT is expired"));
        }
      });
    },

    authenticate: function authenticate(identification, password) {
      var _this2 = this;

      return new _rsvp['default'].Promise(function (resolve, reject) {
        var data = {
          email: identification,
          password: password
        };
        _this2.get('ajax').post('auth/login', {
          contentType: 'application/json; charset=utf-8',
          data: JSON.stringify(data)
        }).then(function (sessionData) {
          _this2.processAuthResponse(sessionData);
          _ember['default'].run(null, resolve, sessionData);
        }, function (xhr) {
          console.log(xhr.errors);
          if (xhr.errors[0] && xhr.errors[0].status === "0") {
            _ember['default'].run(null, reject, "Connection Failed");
          } else if (xhr.errors[0] && xhr.errors[0].status === "401") {
            _ember['default'].run(null, reject, "Invalid Credentials");
          } else if (xhr.errors[0] && xhr.errors[0].status === "429") {
            _ember['default'].run(null, reject, "You have been locked out of the system after too many failed login attempts. This will reset in 30 minutes.");
          } else {
            _ember['default'].run(null, reject, "System Error");
          }
        })['catch'](function (reason) {
          console.log(reason);
          _ember['default'].run(null, reject, "Connection Failed");
        });
      });
    },

    invalidate: function invalidate() {
      _ember['default'].run.cancel(this._refreshTokenTimeout);
      delete this._refreshTokenTimeout;
      return _rsvp['default'].resolve();
    },

    processAuthResponse: function processAuthResponse(sessionData) {
      var jwtPayload = (0, _npmJwtDecode['default'])(sessionData.jwt);
      var expiresAt = jwtPayload.exp * 1000;
      this.scheduleJWTRefresh(expiresAt);
    },

    refreshJWT: function refreshJWT() {
      var _this3 = this;

      this.get('ajax').post('auth/refreshToken').then(function (sessionData) {
        _this3.processAuthResponse(sessionData);
        _this3.trigger('sessionDataUpdated', sessionData);
      }, function () {
        _this3.trigger('sessionDataInvalidated');
      });
    },

    scheduleJWTRefresh: function scheduleJWTRefresh(expiresAt) {
      var now = this.getCurrentTime();
      var wait = expiresAt - now - this.refreshLeeway;

      if (wait > 0) {
        _ember['default'].run.cancel(this._refreshTokenTimeout);
        delete this._refreshTokenTimeout;
        this._refreshTokenTimeout = _ember['default'].run.later(this, this.refreshJWT, wait);
      }
    },

    getCurrentTime: function getCurrentTime() {
      return new Date().getTime();
    }

  });
});