define('consumer-portal/shapes/creditCard', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    number: {
      validators: [(0, _emberCpValidations.validator)('valid-credit-card-number', true)]
    },
    cvc: {
      validators: [(0, _emberCpValidations.validator)('valid-credit-card-cvc', true)]
    },
    exp: {
      validators: [(0, _emberCpValidations.validator)('valid-credit-card-exp', true)]
    }
  });

  exports['default'] = _ember['default'].Object.extend(Validations, {
    name: null,
    number: null,
    cvc: null,
    exp: null
  });
});