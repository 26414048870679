define('consumer-portal/pods/components/credit-card/component', ['exports', 'ember'], function (exports, _ember) {

  /* global Card */

  exports['default'] = _ember['default'].Component.extend({

    tagName: 'div',
    classNames: ['ui grid credit-card-form'],
    card: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var card = new Card({
        form: '.credit-card-form',
        container: '.credit-card',

        placeholders: {
          number: '•••• •••• •••• ••••',
          name: 'John Citizen',
          expiry: '••/••',
          cvc: '•••'
        }
      });
      this.set("card", card);
    },

    actions: {}

  });
});